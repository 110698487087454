import axiosInstance from "@/http";
const state = {
  user: null,
  token: null,
  isAuthenticated: false,
};
const getters = {
  user: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
  token: (state) => state.token,
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
};
const actions = {
  // login
  async login({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/login`, payload);
      console.log(response.data);
      commit("setUser", response.data.user);
      commit("setAuthenticated", true);
      commit("setToken", response.data.token);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async register({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/register`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async verify({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/verify`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async resendOtp({ commit }, payload) {
    try {
      await axiosInstance.post(`/auth/resend-otp`, payload);
    } catch (error) {
      throw error;
    }
  },
  async requestPasswordReset({ commit }, payload) {
    try {
      await axiosInstance.post(`/auth/request-password-reset`, payload);
    } catch (error) {
      throw error;
    }
  },
  async resetPassword({ commit }, payload) {
    try {
      await axiosInstance.post(`/auth/password-reset`, payload);
    } catch (error) {
      throw error;
    }
  },
  async changePassword({ commit }, payload) {
    try {
      await axiosInstance.post(`/account/change-password`, payload);
    } catch (error) {
      throw error;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
