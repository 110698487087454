<template>
  <div class="nnf-page-loader"></div>
</template>

<script>
export default {
  name: "PageSpinner",
};
</script>

<style>
.nnf-page-loader {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  border: 2px solid transparent;
  border-top: 2px solid var(--mainGold);
  border-radius: 50%;
  width: 98px;
  height: 98px;
  animation: spin 460ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
