import Vue from "vue";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import Toast from "vue-toastification";
import vSelect from "vue-select";
import "bootstrap/dist/js/bootstrap.js";
import VueSocialSharing from "vue-social-sharing";
const ToastOptions = {
  timeout: 8000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  position: "top-center",
  transition: "Vue-Toastification__fade",
};
// Custom components
import ButtonSpinner from "@/components/ButtonSpinner";
import PageSpinner from "@/components/PageSpinner";
import InlineError from "@/components/InlineError";
import Pagination from "@/components/Dashboard/Pagination";
Vue.component("ButtonSpinner", ButtonSpinner);
Vue.component("PageSpinner", PageSpinner);
Vue.component("InlineError", InlineError);
Vue.component("Pagination", Pagination);
Vue.use(Toast, ToastOptions);
Vue.use(Vuelidate);
Vue.use(VueSocialSharing);
Vue.component("v-select", vSelect);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
