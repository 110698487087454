import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: HomeView,
  },
  {
    path: "/auth/sign_up",
    name: "CreateAccount",
    component: () => import("@/views/CreateAccount.vue"),
  },
  {
    path: "/auth/forgot_password",
    name: "RequestPasswordReset",
    component: () => import("@/views/RequestPasswordReset.vue"),
  },
  {
    path: "/auth/reset_password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/auth/verify",
    name: "EmailVerify",
    component: () => import("@/views/EmailVerify.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "DashboardHome",
        component: () => import("../views/dashboard/home.vue"),
      },
      {
        path: "/dashboard/transactions",
        name: "Transactions",
        component: () => import("../views/dashboard/transactions.vue"),
      },
      {
        path: "/dashboard/referrals",
        name: "Referrals",
        component: () => import("../views/dashboard/referrals.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuthenticated) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
