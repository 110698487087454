<template>
  <div class="dash-pagination mt-4">
    <button
      class="dash-pagination__button"
      @click="previousPage"
      :disabled="pagination.page === 1 || loadingNextPage"
    >
      <i class="bi bi-chevron-double-left"></i>
    </button>
    <div class="dash-pagination__page-info">
      {{ pagination.page }} of {{ pagination.totalPages }}
    </div>
    <button
      class="dash-pagination__button"
      @click="nextPage"
      :disabled="pagination.page === pagination.totalPages || loadingNextPage"
    >
      <i class="bi bi-chevron-double-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    loadingNextPage: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    nextPage() {
      if (this.pagination.page < this.pagination.totalPages) {
        this.$emit("nextPage");
      }
    },
    previousPage() {
      if (this.pagination.page > 1) {
        this.$emit("previousPage");
      }
    },
  },
};
</script>
<style>
.dash-pagination {
  display: flex;
  align-items: center;
}
.dash-pagination .dash-pagination__button {
  height: 30px;
  width: 30px;
  border: unset;
  color: var(--primaryBlack);
  font-size: 0.875rem;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dash-pagination .dash-pagination__button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  background: transparent;
  color: var(--primaryBlack);
}
.dash-pagination .dash-pagination__page-info {
  font-size: 0.875rem;
  color: var(--mainGold);
  padding: 1rem;
}
</style>
