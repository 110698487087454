import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);
Vue.mixin({
  computed: {
    ...mapGetters({
      User: "auth/user",
    }),
  },
  methods: {
    formatAsMoney(value) {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return value;
      }

      return numberValue.toLocaleString();
    },

    clearQueryParams() {
      const route = { ...this.$route };
      if (Object.keys(route.query).length > 0) {
        route.query = {};
        this.$router.push(route);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  filters: {
    shortenID(value, maxLength) {
      if (value && value.length <= maxLength) {
        return value;
      } else {
        return value.substring(0, maxLength) + "...";
      }
    },
    formatCurrency(value, format = "NGN") {
      const amount = Number(value);
      if (isNaN(amount)) {
        return value;
      }
      return amount.toLocaleString("en-NG", {
        style: "currency",
        currency: format,
      });
    },
    formatTimeOnly(date) {
      return dayjs(date).format("LT");
    },
    formatDateOnly(date, format = "MMMM DD, YYYY") {
      return dayjs(date).format(format);
    },
  },
});
