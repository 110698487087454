<template>
  <div class="auth-wrapper overflow-hidden position-relative">
    <div class="container position-relative">
      <div class="auth-card">
        <div class="mb-3">
          <img
            src="@/assets/brand/nnf_logo.svg"
            class="auth-card__logo"
            alt=""
          />
        </div>
        <div class="my-4 text-center">
          <h3 class="nnf-h4 text-is--dark">Welcome Back</h3>
        </div>
        <form @submit.prevent="loginUser" class="mt-5">
          <div class="form-group mb-3">
            <label for="email" class="nnf-label form-label"
              >Email address</label
            >
            <input
              id="email"
              type="email"
              v-model="email"
              class="nnf-input form-control"
            />
            <InlineError
              message="Please enter valid email"
              v-if="email && $v.email.$invalid"
            />
          </div>
          <div class="form-group mb-3">
            <label for="password" class="nnf-label form-label">Password</label>
            <div class="input-group mb-3">
              <input
                id="password"
                :type="passwordFieldType"
                v-model="password"
                class="nnf-input form-control"
              />
              <span
                class="input-group-text small cursor--pointer"
                @click="showPassword = !showPassword"
              >
                <i v-if="showPassword" class="bi bi-eye-fill text-muted"></i>
                <i v-else class="bi bi-eye-slash-fill text-muted"></i>
              </span>
            </div>
            <InlineError
              message=" Password Should Be at least 8 characters"
              v-if="password && $v.password.$invalid"
            />
          </div>
          <div class="form-group mb-3 text-end">
            <router-link to="/auth/forgot_password" class="nnf-link small"
              >Forgot Password</router-link
            >
          </div>
          <div class="form-group mb-3">
            <button
              type="submit"
              class="nnf-button nnf-button--primary w-100"
              :disabled="isLoading || $v.$invalid"
            >
              Login <ButtonSpinner v-if="isLoading" />
            </button>
          </div>
          <div class="form-group text-center mt-4">
            <p class="small text-is--dark mb-0">
              Are you new here?
              <router-link to="/auth/sign_up" class="nnf-link small"
                >Create Account</router-link
              >
            </p>
          </div>
        </form>
      </div>
      <img
        src="@/assets/img/bnb.svg"
        class="f-img f-img__binance is--floating-2"
        alt="BNB Logo"
      />
      <img
        src="@/assets/img/eth.svg"
        class="f-img f-img__eth is--floating-1"
        alt="BNB Logo"
      />
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  metaInfo: {
    title: "Login",
  },
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      showPassword: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    async loginUser() {
      this.isLoading = true;
      try {
        const data = {
          email: this.email,
          password: this.password,
        };
        await this.$store.dispatch("auth/login", data);
        this.$toast("Login Success!");
        this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          this.$toast.error(error.response.data.error);
        } else {
          this.$toast.error("An error occurred. Please try again");
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
